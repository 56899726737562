.course-overview-lecture {
  &-disabled {
    opacity: 0.5;
    cursor: not-allowed !important;
  }

  &-active,
  &:hover {
    background-color: var(--surface-color);
  }

  .course-overview-lecture-header {
    display: flex;
    justify-content: space-between;
  }

  &-interaction,
  &-video {
    display: flex;
    flex-direction: column;
    padding: 1rem .5rem;
    cursor: pointer;

    &-public {
      .course-overview-lecture-video-name,
      .course-overview-lecture-video-name-preview-button {
        text-decoration: underline;
        color: var(--secondary-color);
      }
    }

    &-content {
      display: flex;
      align-items: center;
      font-size: 12px;

      svg {
        margin-right: 4px;
      }
    }
  }
}

.lecture-preview {
  font-size: 1rem !important;
}

.lecture-link {
  color: #fff;
}
