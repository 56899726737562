.code-editor-file-explorer-main-wrapper {
  // width: 250px;
  max-height: 100vh;
  display: flex;
  flex-direction: column;

  .file-explorer-input-name-wrapper {
    margin: 10px;
    .file-explorer-form {
      display: flex;
      gap: 8px;
    }
  }

  .file-explorer-file-content-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    display: flex;
    padding: 5px;
    width: 250px;

    .file-explorer-file-content {
      display: flex;
      align-items: center;
    }

    .file-explorer-file-actions-wrapper {
      width: 24px;
    }
  }

  .file-explorer-file-actions {
    display: flex;
    color: #fff;

    &:not(.file-explorer-file-actions-open) {
      .file-explorer-file-actions-toggle {
        visibility: hidden;
      }
    }

    &-menu {
      position: absolute;
      display: flex;
      flex-direction: column;
      width: max-content;
      z-index: 1000;
      background-color: var(--background-color);
      padding: 10px;

      div {
        display: flex;
        align-items: center;
      }
    }
  }

  &:hover {
    .file-explorer-file-actions {
      &-toggle {
        visibility: visible !important;
      }
    }
  }
}