.code-exercise-instructions {
  &-title {
    padding: 20px;
    margin: 0 0 20px 0;
    border-bottom: var(--border);
    position: sticky;
    top: 0;
    background-color: var(--surface-color);
    display: flex;
    align-items: center;
    z-index: 1;
  }
}
