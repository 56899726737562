.course-preview-detailed-description {
  .detailed-description {
    height: auto;

    &-short {
      height: 280px;
      overflow: hidden;

      mask-image: linear-gradient(#ffffff, #ffffff, rgba(255, 255, 255, 0));
      -webkit-mask-image: linear-gradient(#ffffff, #ffffff, rgba(255, 255, 255, 0));
    }
  }

  &-show-more {
    margin-top: 1rem;
    color: var(--primary-color);
    cursor: pointer;
    font-weight: 700;

    &-btn {
      display: flex;
      align-items: center;

      svg {
        margin-left: 5px;
      }
    }
  }

  :global(.exercise-description-parsed) {
    padding: 0;
    p + p,
    p + ul,
    p + ol,
    ul + p,
    ul + ol,
    ol + ol,
    ol + p,
    ol + ul {
      margin-top: 0.8rem;
    }
  }
}
