.paypal-payment-form {
  color: #000;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(255, 255, 255,0.8);
  border-radius: 6px;

  &-inner {
    width: 100%;
  }
}
