.exercise-instruction-item {
  display: flex;
  margin: 20px;

  .MuiCheckbox-root {
    padding: 0;
    height: 24px;
    margin-right: 10px;
  }

  :global(.exercise-description-parsed) {
    padding: 0;
  }

  &-disabled {
    opacity: 0.4;
  }
}
