.course-preview {
  display: flex;
  align-items: flex-start;
  width: 100%;
  &-main-card {
    width: 100%;
  }
}

.video-player-wrapper {
  .course-lecture-video-player {
    margin-top: 12px;
  }
}
