.code-editor-wrapper {
  display: flex;
  // width: 50%;
  flex: 1;
  height: 100%;
  border: var(--border);

  .code-editor {
    &-header {
      width: 100%;
      display: flex;
      align-items: center;

      &-file-icon {
        margin: 0 10px;
        cursor: pointer;
      }
    }

    &-empty-state {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      background-color: var(--surface-color);

      &-center {
        display: flex;
        flex-direction: column;
        svg {
          font-size: 60px;
        }
        align-items: center;
        justify-content: center;
      }
    }

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;

    .code-editor-buttons {
      width: 100%;
      background-color: var(--background-color);
      border: var(--border);
      bottom: 0;
      display: flex;
      padding: 10px;

      &-icons {
        :global(.startIcon) {
          svg {
            margin: 0 !important;
            font-size: 24px !important;
          }
        }
      }
    }
  }
}
.monaco-editor-input,
.code-editor {
  display: flex;
  width: 100%;
  height: 100%;
}
.monaco-editor-input .monaco-editor {
  display: flex;
}

.code-editor-error-message {
  left: 10px;
  width: calc(100% - 40px);
  background-color: var(--background-color);
  border: var(--border);
  // border-color: var(--red);
  bottom: 70px;
  position: absolute;
  z-index: 1000;

  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    background-color: var(--primary-color);
  }

  &-content {
    color: var(--red);
  }
}
