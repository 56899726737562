.course-lecture-video {
  display: flex;
  width: 100% !important;
  position: relative;

  &.fullheight {
    height: var(--max-content-height) !important;

    .course-lecture-video-player {
      width: 100% !important;
      height: 100% !important;
    }
  }

  &-player {
    &-arrow {
      z-index: 1000;
      top: 0;
      bottom: 0;
      margin: auto;
      height: 50px;
      position: absolute;
      display: flex;
      align-items: center;
      cursor: pointer;

      &-icon {
        height: 100% !important;
        background-color: var(--primary-color);
      }

      &-left {
        left: 0;
        .course-lecture-video-player-arrow-content {
          margin-left: 10px;
        }
      }
      &-right {
        right: 0;
        .course-lecture-video-player-arrow-content {
          margin-right: 10px;
        }
      }

      &-content {
        background-color: var(--secondary-color);
        padding: 0 10px;
        height: 100%;
        display: none;
        align-items: center;
      }

      &:hover {
        .course-lecture-video-player-arrow-content {
          display: flex;
        }
      }
    }
  }

  &-with-overview {
    width: calc(100% - var(--sidebar-width)) !important;
    height: var(--max-content-height) !important;
  }
}
