.code-exercise {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  overflow: auto;
  position: relative;
  border-right: var(--border);

  &-fixed {
    overflow: hidden;
  }

  &-finished-overlay {
    position: absolute;
    z-index: 10000;
    width: 100%;
    height: 100%;
    background-color: rgb(91 74 228 / 95%);

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  &-title {
    display: flex;
    align-items: center;
    padding: 20px;
    margin: 0;
    border-bottom: var(--border);
    position: sticky;
    top: 0;
    background-color: var(--surface-color);
    z-index: 1;
  }
}
