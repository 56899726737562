.course-lecture-hint-wrapper {
  background-color: #cb4d9466;

  .course-section-dropdown-content {
    padding: 10px;
  }

  .course-lecture-hint {
    display: flex;
    padding: 10px;

    background-color: var(--secondary-color);
    cursor: pointer;
    display: flex;
  }

  .course-section-dropdown-title-content {
    width: 100%;
  }
}
