.course-lecture-footer {
  position: fixed;
  bottom: 0;
  background: var(--surface-color);
  width: 100%;
  height: var(--lecture-footer-height);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  height: 72px;
  z-index: 100;
  border-top: var(--border);
}

.course-lecture-footer-left-side {
  flex: 1;
}

.course-lecture-footer-middle {
  flex: 1;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.course-lecture-footer-right {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}
