.codeeditor-wrapper {
  display: flex;
  flex: 1 1;
  height: var(--max-content-height);

  .code-exercise {
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
    overflow: auto;
    border-right: var(--border);

    &-title {
      display: flex;
      align-items: center;
      padding: 20px;
      margin: 0;
      border-bottom: var(--border);
      position: sticky;
      top: 0;
      background-color: var(--surface-color);
      z-index: 1;
    }
  }

  .illustration {
    display: flex;
    flex-direction: column;
    flex: 2;
    align-items: center;
    justify-content: center;
    position: relative;

    &-image {
      object-fit: contain;
      width: 100%;
      height: auto;
      max-height: 100%;
      max-width: 100%;
    }

    .illustration-footer {
      width: 100%;
      display: flex;
      padding: 20px;
      margin: 0;
      border-top: var(--border);
      position: absolute;
      left: 0;
      bottom: 0;
      background-color: var(--background-color);
      z-index: 1;
      justify-content: flex-end;

      &-buttons {
      }
    }
  }
}
