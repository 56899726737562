.cards-container {
  width: 100%;
  padding-top: 50px;
  display: flex;
  gap: 18px;
  @media screen and (max-width: 960px) {
    flex-direction: column;
    gap: 50px;
  }
}
