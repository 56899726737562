.course-overview-section-groups {
  width: 100%;
  max-height: calc(var(--max-content-height) - var(--top-menu-height));
  overflow: scroll;

  &:not(.course-overview-section-groups-preview) {
  }

  &-preview {
    max-height: inherit !important;
    overflow: auto !important;

    :global(course-overview-section-title) {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .course-overview-section-group {
    border: 5px solid var(--surface-color);
  }
}
