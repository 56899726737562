.course-preview {
  display: flex;
  align-items: flex-start;
  width: 100%;
  &-main-card {
    width: 100%;
  }
}

.course-resources {
  border: 1px solid var(--primary-color);
  background: var(--background-color);

  .course-resources-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;

    color: var(--text-color);
    text-decoration: none;
    border-bottom: 1px solid var(--primary-color);

    span {
      display: flex;
      align-items: center;
    }

    .resource-download {
      color: var(--secondary-color);
      text-decoration: underline;
    }

    .MuiSvgIcon-root {
      margin-right: 10px;
    }

    &:hover {
      background-color: var(--surface-color) !important;
    }
  }
}
