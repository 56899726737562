.code-editor-error-message {
  background-color: var(--background-color);
  z-index: 1000;

  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    background: var(--gradient-background);
    font-weight: 800;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  }

  &-content {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
    background: linear-gradient(to right, var(--code-background-color), var(--background-color));
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    padding: 30px;
    border: 1px solid #ffffff4d;
    border-top: none;
  }

  :global(.exercise-description-parsed){
    padding: 0;
  }
}
