.freeLabel {
  display: flex;
  align-items: center;
  background-color: var(--secondary-color);
  margin-left: 6px !important;
  padding: 2px 5px;
  margin: 0;
  border-radius: 8px;
  font-size: 14px;
}

.course-section-dropdown-title {
  width: 100%;
  background-color: var(--primary-color);
  padding: 10px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  justify-content: space-between;

  &-content {
    width: 100%;

    .course-overview-section-title {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
  }
}
