.code-editor-preview {
  flex: 1;
  display: flex;
  flex-direction: column;

  iframe {
    background-color: #fff;
    height: 100%;
  }

  &-header {
    display: flex;
    align-items: center;
    background-color: var(--surface-color);
    padding: 10px;

    &-buttons {
      display: flex;
    }

    &-url {
      display: flex;
      flex: 1;
      border-radius: 10px;
      background-color: var(--background-color);
      padding: 10px;
      margin: 0 5px;
    }
  }
}
