.answers-container {
  display: flex;
  align-items: center;
  height: 60px;
  background-color: var(--background-color);
  border: 1px solid var(--border-color);
  border-radius: 5px;
  cursor: pointer;

  &:hover,
  &.selected {
    background-color: var(--primary-color);
  }
}
.answer-messages-container,.success-message {
  padding: 20px;
}

.error-explanation {
  margin-top: 10px;
}
