.course-main-information {
  margin-top: 10px;
  display: flex;
  align-items: center;

  div {
    display: flex;
    align-items: center;
    margin-right: 20px;

    svg {
      margin-right: 5px;
    }
  }
}
