.container {
  border: 3px solid var(--primary-color);
  border-radius: 3px;
  width: 30px;
  height: 30px;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}
