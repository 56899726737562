.code-editor-preview {
  flex: 1;
  white-space: pre-wrap;

  &-header {
    display: flex;
    align-items: ce;
    width: 100%;
    background: var(--background-color);
    height: 44px;

    &-card {
      cursor: pointer;
      background: var(--background-color);
      display: flex;
      align-items: center;
      padding: 10px;

      &-active {
        background: var(--surface-color);
      }
    }
  }

  .content-wrapper {
    height: calc(100% - 44px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .code-editor-preview-content {
      height: calc(100% - 100px);
      overflow: auto !important;

      &-error {
        color: var(--red) !important;
      }

      &-system {
        color: var(--primary-color) !important;
      }
    }

    .input-wrapper {
      background-color: var(--background-color);
      border-radius: 10px;
      padding: 10px;
      height: 90px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      &-inner {
        display: flex;
        border-top: var(--border);
        margin-top: 10px;
        padding-top: 10px;

        .input {
          background-color: transparent;
          border: 0;
          outline: 0;
          color: #fff;
          font-size: 16px;
          width: 100%;
        }
      }
    }
  }
}
