.course-overview {
  position: fixed;
  width: var(--sidebar-width);
  max-width: 100vw;
  z-index: 10;

  @media (max-width: 766px) {
    width: 100vw;
  }

  top: var(--top-menu-height);
  right: 0;
  background-color: var(--background-color);
  border: 1px solid var(--border-color);
  height: 100%;

  &-header {
    display: flex;
    justify-content: space-between;
    padding: 20px;

    &-close-icon {
      cursor: pointer;
    }
  }

  &-section-groups {
    max-height: calc(var(--max-content-height) - var(--top-menu-height));
    overflow: auto;
  }

  &-section-group {
    // background-color: var(--surface-color);
    // margin-bottom: 10px;
  }

  &-hidden {
    position: fixed;
    background-color: var(--background-color);
    padding: 5px;
    width: 50px;
    height: 50px;
    z-index: 1000;
    top: 200px;
    right: 0;
    transition: 500ms;
    border: var(--border);
    overflow: hidden;
    cursor: pointer;

    .course-overview-hidden-content {
      display: flex;
      align-items: center;
      width: 170px;

      .course-overview-hidden-icon {
        margin-right: 10px;
      }
    }

    &:hover {
      width: 175px;
    }
  }
}
