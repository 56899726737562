.container {
  background-color: var(--background-color);
  flex: 2;
  height: var(--max-content-height);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  .card {
    max-width: 640px;
    max-height: 90%;
    overflow: auto;
    width: 90vw;

    .innerContainer {
      margin: auto;
      padding: 20px 0;
      width: 80%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      white-space: pre-wrap;

      .buttons {
        width: 60%;
      }
    }
  }

  &-with-overview {
    width: calc(100% - var(--sidebar-width)) !important;
    height: var(--max-content-height) !important;
  }
}
