.rating-preview {
  display: flex;
  align-items: center;

  .rating-stars-number {
    color: var(--yellow);
    font-size: 20px;
    font-weight: 500;
  }

  &-simple {
    margin: 0;

    .rating-stars-number {
      color: #fff;
    }
  }

  .rating-stars {
    display: flex;
    align-items: center;

    &-number {
      margin-right: 6px;
    }

    &-count {
      margin: 0 10px;
    }
  }
}
