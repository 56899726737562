.course-preview-main-card {
  .preview-banner-wrapper {
    @media (min-width: 767px) {
      display: none;
    }
  }
}

.course-preview-includes {
  p {
    text-align: center;
  }
}

.course-preview-mobile-banner {
  @media (min-width: 767px) {
    display: none;
  }

  left: 0;
  position: fixed;
  bottom: 0;
  background-color: var(--surface-color);
  border: 1px solid var(--code-background-color);
  box-shadow: inset 0 0 3px var(--border-color);
  width: 100%;
  display: flex;
  padding: 1rem;

  h2,
  h4,
  h4 {
    margin: 0;
    max-width: 330px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &-left-side {
    @media (max-width: 599px) {
      display: none;
    }

    display: flex;
    justify-content: center;
    flex-direction: column;
    flex: 3;

    .rating-preview {
      margin: 0;
    }
  }

  &-right-side {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 2;

    .course-price {
      margin: 0;
    }

    button {
      width: 100%;
      height: 50px;
      margin-bottom: 0;
      margin-left: 1rem;
    }
  }
}
