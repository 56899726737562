.mobile-menu-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  border-bottom: 1px solid var(--border-color);
  padding: 20px 0px;
}

.mobile-menu-item-left {
  display: flex;
  align-items: center;
  gap: 16px;
}
