.tab-menu,
.tab-menu-language {
  display: flex;
  flex: 1;
  background: var(--background-color);
  height: 44px;
  z-index: 10;

  &-item {
    display: flex;
    align-items: center;

    background: #181722;
    padding: 10px;

    &-active {
      background: var(--surface-color);
    }
  }
}

.tab-menu-language-item {
  position: absolute;
  // border: var(--border);
}
