.top-menu-container {
  z-index: 100;
  position: fixed;
  top: 0;
  width: 100%;
  background-image: linear-gradient(90deg, #7700e7 0%, #d50293 100%);

  .top-menu-container-inner {
    background-color: rgba(0, 0, 0, 0.39);

    .top-menu-container-content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: var(--top-menu-height);
      padding: 10px;

      .top-menu-left-side {
        display: flex;
        align-items: center;
        height: 100%;

        img {
          max-width: 150px;
        }

        .top-menu {
          &-title {
            font-size: 2rem !important;
          }

          &-title,
          &-items {
            @media (max-width: 766px) {
              display: none;
            }

            display: flex;
            align-items: center;
            height: 80%;
            margin-left: 1rem;
            padding-left: 1rem;
            border-left: 1px solid var(--primary-color);
          }
        }
      }

      .top-menu-right-menu {
        display: flex;

        &-leave-rating,
        &-your-progress {
          display: flex;
          align-items: center;
        }

        &-leave-rating {
          margin-right: 20px;
          cursor: pointer;

          .top-menu-star {
            margin-right: 10px;
          }
        }

        &-your-progress {
          position: relative;
          cursor: pointer;

          .progressbar {
            display: flex;
            align-items: center;

            &-icon {
              margin-right: 10px;
            }

            &-background {
              color: var(--surface-color);
            }

            &-foreground {
              color: var(--primary-color);
            }
          }

          .top-menu-progressbar-toolbar {
            background: var(--surface-color);
            padding: 10px;
          }
        }

        &-share {}
      }
    }
  }
}

.leave-rating-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  .rating-description {
    background-color: transparent;
    width: 100%;
    margin: 20px 0;
    padding: 10px;
    color: var(--text-color);
  }
}