.pricing-card {
  cursor: pointer;
  background-color: var(--background-color) !important;

  .monthly-price {
    div {
      margin: 0;
    }
  }

  .annual-monthly-price {
    color: graytext;

    h1 {
      font-size: 1rem !important;
    }
  }

  &:hover,
  &.active-card {
    background-color: var(--primary-color) !important;

    .annual-monthly-price {
      color: #fff;
    }
  }
}
